import React, { useState } from "react";
import "./myorders.css";

const MyOrders = () => {
  const [customerNumber, setCustomerNumber] = useState("");

  const [orders, setOrders] = useState([]);
  const searchOrder = async () => {
    try {
      const res = await fetch(
        `https://coinbazar.shop/coinbazar/fetch_my_order.php?customer_number=${customerNumber}`
      );

      if (!res.ok) {
        throw new Error("Failed to fetch orders");
      }

      const response = await res.json();

      if (response && response.length > 0) {
        console.log(response);
        setOrders(response);
      } else {
        console.log("No products found");
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
  };

  // RAISE ISSUE
  const raiseIssue = (data) => {
    console.log(data);
  };

  return (
    <div className="my-orders-bg">
      <div className="my-orders-input-container">
        <input
          type="text"
          placeholder="please enter your mobile number to search your orders"
          onChange={(e) => setCustomerNumber(e.target.value)}
        />
        <button onClick={searchOrder}>See My Orders</button>
      </div>
      <div className="my-orders-container">
        {orders.length === 0 ? (
          <div className="default">
            <h4>You've no products to show!!!</h4>
          </div>
        ) : (
          orders.map((item) => (
            <div className="orders-box" key={item.id}>
              <div className="orders-box-image">
                <img
                  src={`https://coinbazar.shop/coinbazar/uploads/${item.product_image}`}
                  alt=""
                />
              </div>
              <div className="orders-box-text">
                <h4>{item.product_name}</h4>
                <p>Purchase: {item.product_package_name}</p>
                <p>Price: {item.product_package_price} Tk.</p>
              </div>
              <div className="orders-box-issue">
                <i
                  className="fa-solid fa-circle-exclamation"
                  onClick={() => raiseIssue(item.id)}
                ></i>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyOrders;

import React from "react";
import { Link } from "react-router-dom";

const CategoryHomeConatiner = ({ products }) => {
  return (
    <div className="category-container-grid-homepage">
      {products.map((product) => (
        <div className="box" key={product.id}>
          <Link to={`/description/${product.id}`}>
            <img
              src={`https://coinbazar.shop/coinbazar/uploads/${product.image}`}
              alt=""
            />
            <h1>{product.price}</h1>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default CategoryHomeConatiner;

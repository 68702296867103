import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-col">
        <div className="footer-col-1">
          <h4>CoinBazar</h4>
          <p>coins that gives you joy!</p>
        </div>
        <div className="footer-col-2">
          <div className="links">
            <a href="">home</a>
            <a href="">about</a>
            <a href="">placed orders</a>
            <a href="">my account</a>
            <a href="">live</a>
            <a href="">blogs</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import CategoryHomeConatiner from "./CategoryHomeConatiner";
import { Link } from "react-router-dom";

const Categories = ({ category, products }) => {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const filteredData =
      category === "All"
        ? products
        : products.filter((item) => item.category === category);
    setProduct(filteredData);
  }, [category, products]);

  return (
    <div className="category-parent-inner-homepage">
      {product.length > 0 ? (
        <div>
          <div className="category-heading-homepage">
            <h5>{category}</h5>
          </div>
          <CategoryHomeConatiner products={product.slice(0, 5)} />
          <div className="view-more-button">
            <Link to={`/category/${category}`}>
              <button>View More</button>
            </Link>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Categories;

import React from "react";
import "./header.css";
import CategoryHeader from "./CategoryHeader";
import CartHeader from "./CartHeader";
import { Link } from "react-router-dom";

const Header = ({ category, cartData }) => {
  return (
    <div className="header-bg">
      <div className="header-container">
        <div className="header-logo">
          <Link to="/">
            <h5>CoinBazar.shop</h5>
          </Link>
        </div>
        <div className="header-links">
          <CategoryHeader category={category} />
          <CartHeader cartCount={cartData} />
        </div>
      </div>
      <div className="header-container">
        <div className="buttons-header">
          <Link to={"/my-orders"}>
            <button>See My Orders</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";

const ProductsCart = ({ cartItems, updateCartDataInParent }) => {
  let taxes = 0;
  let totalPrice = 0;

  cartItems.map((item) => {
    totalPrice += item.package_price * 1;
  });
  let GrandTotal = totalPrice;

  //   DELETE FROM LOCALSTORAGE
  const deleteCart = (id) => {
    // Find the index of the item with the specified ID
    const index = cartItems.findIndex((item) => item.id === id);

    // If the item is found, remove it from the array
    if (index !== -1) {
      cartItems.splice(index, 1);

      localStorage.setItem("cartData", JSON.stringify(cartItems));
      updateCartDataInParent();
    }
  };

  return (
    <div className="cart-items-fetch-container">
      <div className="cart-items-fetch-heading">
        <h3>Added Itmes</h3>
      </div>

      {cartItems.length === 0 ? (
        <h4>No Products Added To Show!</h4>
      ) : (
        <div className="cart-items-fetch-container-boxes">
          {cartItems.map((item) => (
            <div className="box">
              <div className="cart-items-fetch-box-image">
                <img
                  src={`https://coinbazar.shop/coinbazar/uploads/${item.image}`}
                  alt=""
                />
              </div>
              <div className="cart-items-fetch-box-texts">
                <h3>{item.name}</h3>
                <p>Package Name: {item.package_name}</p>
                <p>Price: {item.package_price} TK.</p>
                <p>{item.description}</p>
              </div>
              <div className="cart-items-fetch-box-buttons-functional">
                <button onClick={() => deleteCart(item.id)}>
                  <i className="fa-solid fa-trash"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      {cartItems.length === 0 ? (
        ""
      ) : (
        <div className="totalPrice-calculation">
          <h3>Total Price: {totalPrice} Tk.</h3>
          <h3>Taxes: {taxes} TK.</h3>
          <h3>Total Payable: {GrandTotal} Tk.</h3>
        </div>
      )}
    </div>
  );
};

export default ProductsCart;

import React, { useEffect, useState } from "react";
import Categories from "./Categories";
import "./homepage.css";

const Homepage = ({ category, myproducts }) => {
  return (
    <div className="homepage-bg-p-b">
      <div className="banner-image-homepage">
        <img src="banner.png" alt="" />
      </div>
      {category.map((item) => (
        <div className="category-parent-homepage" key={item.id}>
          <Categories category={item.category} products={myproducts} />
        </div>
      ))}
    </div>
  );
};

export default Homepage;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const CategoryHeader = ({ category }) => {
  const [searchCategory, setSearchCategory] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (searchCategory) {
      navigate(`/category/${searchCategory}`);
      console.log(searchCategory);
    }
  }, [searchCategory]);

  return (
    <div className="header-category-select">
      <select name="" id="" onChange={(e) => setSearchCategory(e.target.value)}>
        <option value={""}>Select a category</option>
        {category.map((item) => (
          <option value={item.category} key={item.id}>
            {item.category}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CategoryHeader;

import React, { useEffect, useState } from "react";
import FormCart from "./FormCart";
import ProductsCart from "./ProductsCart";
import "./cart.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Cart = ({ product, updateCartCountMain }) => {
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    if (product) {
      setCartItems(product);
    } else {
      setCartItems([]);
    }
  }, [product]);

  function updateCart() {
    updateCartCountMain();
  }

  const navigate = useNavigate();
  function receiveData(data) {
    if (cartItems) {
      if (cartItems.length > 0) {
        axios
          .post("https://coinbazar.shop/coinbazar/order_place.php", {
            customerData: data,
            productData: cartItems,
          })
          .then((response) => {
            if (response.data.trim() === "success") {
              alert("Your order has been placed.");
              localStorage.removeItem("cartData");
              setCartItems([]);
              navigate("/my-orders");
              window.location.reload();
            } else {
              console.log("Error:", response.data);
              alert("Failed to place the order. Please try again.");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            alert(
              "An error occurred while processing your order. Please try again."
            );
          });
      } else {
        alert("Cart Is Empty!!");
      }
    }
  }

  return (
    <div className="cart-parent-container-all">
      <div className="cart-heading">
        <h3>Cart</h3>
      </div>
      <div className="cart-component-container">
        <FormCart sendDatas={receiveData} />
        <ProductsCart
          cartItems={cartItems}
          updateCartDataInParent={updateCart}
        />
      </div>
    </div>
  );
};

export default Cart;

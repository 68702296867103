import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PricingPlan from "./PricingPlan";
import "./descriptionPage.css";

const Description = ({ myproducts, refreshCartCount }) => {
  const { pid } = useParams();
  const [pdata, setDPdata] = useState([]);

  useEffect(() => {
    const filteredData = myproducts.filter((item) => item.id === pid);
    setDPdata(filteredData);
  }, [myproducts]);

  // ADD TO CART

  const navigate = useNavigate();
  const getDatapricing = (data) => {
    const mergedArray = pdata.map((item, index) => ({
      ...item,
      ...data[index],
    }));
    console.log(mergedArray);
    const PrvLocalData = JSON.parse(localStorage.getItem("cartData")) || [];
    if (PrvLocalData) {
      const mergerlocaldata = [...PrvLocalData, ...mergedArray];
      const setLocal = localStorage.setItem(
        "cartData",
        JSON.stringify(mergerlocaldata)
      );
    } else {
      const setLocal = localStorage.setItem(
        "cartData",
        JSON.stringify(mergedArray)
      );
    }

    navigate("/cart");
    refreshCartCount();
  };
  return (
    <div className="descriptionBg">
      {pdata.map((item) => (
        <div key={item.id}>
          <div className="descriptionHeader">
            <a href="">Home</a>
            <p>
              <i className="fa-solid fa-arrow-right"></i>
            </p>
            <a href={`/category/${item.category}`}>{item.category}</a>
          </div>

          <div className="container">
            <div className="imageContainer">
              <img
                src={`https://coinbazar.shop/coinbazar/uploads/${item.image}`}
                alt=""
              />
              <h4>{item.name}</h4>

              <p>{item.description}</p>
            </div>
            <PricingPlan
              pid={pid}
              product={item}
              passSelectedData={getDatapricing}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Description;

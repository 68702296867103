import React, { useEffect, useState } from "react";
import CategoryHomeContainer from "../Homepage/CategoryHomeConatiner";

const SearchPage = ({ category, products }) => {
  const [product, setProduct] = useState([]);
  useEffect(() => {
    const filteredData =
      category === "All"
        ? products
        : products.filter((item) => item.category === category);
    setProduct(filteredData);
  }, [category, products]);

  return (
    <div className="category-parent-inner-homepage">
      {product.length > 0 ? (
        <div>
          <div className="category-heading-homepage">
            <h5>{category}</h5>
          </div>
          <CategoryHomeContainer products={product} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchPage;

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Homepage from "./components/Homepage/Homepage";
import "./routing.css";
import Description from "./components/Description/Description";
import CategorySearch from "./components/CategorySearch/CategorySearch";
import Cart from "./components/Cart/Cart";
import MyOrders from "./components/Myorders/MyOrders";

function Routing() {
  const [category, setCategory] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchCategoryRow = async () => {
      try {
        const res = await fetch(
          "https://coinbazar.shop/coinbazar/categories.php"
        );
        const response = await res.json();
        console.log(response);
        setCategory(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategoryRow();

    setProducts([]);
    const fetchbyCategoryRow = async () => {
      try {
        const res = await fetch(`https://coinbazar.shop/coinbazar/fetch.php`);
        const response = await res.json();
        if (response) {
          console.log(response);
          setProducts(response);
        } else {
          console.log([]);
          setProducts([]);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchbyCategoryRow();
  }, []);

  const [cartDatas, setCartDatas] = useState([]);
  useEffect(() => {
    cartCount();
  }, []);

  function cartCount() {
    const mycartDatas = JSON.parse(localStorage.getItem("cartData"));
    setCartDatas(mycartDatas);
  }

  function AfterDltCartCount() {
    const res = JSON.parse(localStorage.getItem("cartData"));
    if (res) {
      if (res.length == 0) {
        console.log("no data found!");
        setCartDatas([]);
      } else {
        console.log(res);
        setCartDatas(res);
      }
    } else {
      console.log("no data");
    }
  }
  return (
    <div className="Routing">
      <Header category={category} cartData={cartDatas} />
      <Routes>
        <Route
          path="/"
          element={<Homepage category={category} myproducts={products} />}
        />
        <Route
          path="/description/:pid"
          element={
            <Description myproducts={products} refreshCartCount={cartCount} />
          }
        />
        <Route
          path="/category/:category"
          element={<CategorySearch myproducts={products} />}
        />

        <Route
          path="/cart"
          element={
            <Cart product={cartDatas} updateCartCountMain={AfterDltCartCount} />
          }
        />
        <Route path="/my-orders" element={<MyOrders />} />
      </Routes>
    </div>
  );
}

export default Routing;

import React, { useEffect, useState } from "react";

const PricingPlan = ({ pid, passSelectedData }) => {
  const [pricing, setPricing] = useState([]);
  useEffect(() => {
    const fetchpricing = async () => {
      try {
        const res = await fetch(
          `https://coinbazar.shop/coinbazar/pricing_fetch.php?pid=${pid}`
        );
        const response = await res.json();
        setPricing(response);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchpricing();
  }, [pid]);

  //   FETCHING DATA FOR PRICING
  //   FETCHING DATA FOR PRICING
  //   FETCHING DATA FOR PRICING
  //   FETCHING DATA FOR PRICING

  const [totalPrice, setTotalPrice] = useState("");
  const [selectPrice, setSelectPrice] = useState([
    {
      package_name: "",
      package_price: "",
    },
  ]);

  const handleSelect = (data) => {
    setTotalPrice(data.package_price);
    setSelectPrice([
      {
        package_name: data.package_name,
        package_price: data.package_price,
      },
    ]);
  };

  function addToCart() {
    if (selectPrice) {
      if (selectPrice.length > 0) {
        passSelectedData(selectPrice);
      } else {
        alert("select a plan!");
      }
    }
  }
  return (
    <div className="textContainer">
      <div className="heading">
        <p>1</p>
        <h3>Select Amount</h3>
      </div>

      <div class="container">
        {pricing.map((item) => (
          <div key={item.id} className="pricing-extra">
            <input
              type="radio"
              id={item.id}
              value={item.package_price}
              onClick={(e) => handleSelect(item)}
            />
            <label for={item.id}>{item.package_name}</label>
          </div>
        ))}
      </div>

      <div className="buttonDiv">
        {totalPrice ? (
          <div className="calculate">
            <div className="numbers">
              <h3>Total:</h3>
              <p>{totalPrice} BDT</p>
            </div>
            {/* <div className="increaseBtn">
              <button>+</button>
              <h4>1</h4>
              <button>-</button>
            </div> */}
          </div>
        ) : (
          ""
        )}

        <button onClick={addToCart}>Add To Cart</button>
      </div>
    </div>
  );
};

export default PricingPlan;

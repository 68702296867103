import React, { useState } from "react";

const FormCart = ({ sendDatas }) => {
  const [customerName, setCustomerName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [gameID, setGameID] = useState("");
  const [emailID, setEmailID] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [bkashNum, setBkashNum] = useState("");

  function placeOrder() {
    if (!customerName) {
      alert("Enter Your Name Please");
    } else if (!mobileNumber) {
      alert("fill up your contact number please!");
    } else if (!gameID) {
      alert("enter your game id!");
    } else if (!emailID) {
      alert("some games require email to send data so please fill the field!");
    } else {
      setVisible(true);
    }
  }

  const [isVisible, setVisible] = useState(false);

  const finalSubmitPlace = () => {
    const customerFormData = {
      customerName,
      mobileNumber,
      gameID,
      emailID,
      transactionID,
      bkashNum,
    };
    sendDatas(customerFormData);
    if (transactionID) {
    }
  };

  function nonvisible() {
    setVisible(false);
  }
  return (
    <div className="form-cart-parent-big">
      {isVisible && (
        <div className="bkash-form-div-main">
          <div className="bkash-close" onClick={nonvisible}>
            <i className="fa-solid fa-x"></i>
          </div>
          <div className="bkash-form-div">
            <div className="bkash-logo">
              <img src="images.jpg" alt="" />
            </div>
            <div className="bkash-heading">
              <h3>Steps:</h3>
            </div>
            <div className="bkash-instrustions">
              <ul>
                <li>"Send money" on this number "01887368332"</li>
                <li>Enter the transaction number in the box given below</li>
              </ul>
            </div>
            <div className="bkash-form">
              <input
                type="text"
                placeholder="Transaction ID"
                onChange={(e) => setTransactionID(e.target.value)}
              />
            </div>

            <div className="bkash-instrustions">
              <ul>
                <li>Enter Your Bkash number in the box given below</li>
              </ul>
            </div>
            <div className="bkash-form">
              <input
                type="text"
                placeholder="Your Bkash Number"
                onChange={(e) => setBkashNum(e.target.value)}
              />
            </div>

            <div className="bkash-button-submit">
              <button onClick={finalSubmitPlace}>Submit</button>
            </div>
          </div>
        </div>
      )}
      <div className="form-cart-heading">
        <h3>Your Details</h3>
      </div>
      <div className="form-cart-customer-form">
        <div className="inputs-cart-customer-form">
          <label htmlFor="customer-name">Enter Your Name Here:</label>
          <input
            type="text"
            placeholder="habib..."
            id="customer-name"
            onChange={(e) => setCustomerName(e.target.value)}
          />
        </div>
        <div className="inputs-cart-customer-form">
          <label htmlFor="customer-number">
            Enter Your mobile Number Here:
          </label>
          <input
            type="text"
            placeholder="+880-01789-90247"
            id="customer-number"
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
        <div className="inputs-cart-customer-form">
          <label htmlFor="customer-game-id">Enter Your game-id Here:</label>
          <input
            type="text"
            placeholder="565-678"
            id="customer-game-id"
            onChange={(e) => setGameID(e.target.value)}
          />
        </div>
        <div className="inputs-cart-customer-form">
          <label htmlFor="customer-email">Enter Your email Here:</label>
          <input
            type="email"
            placeholder="habib@gmail.com"
            id="customer-email"
            onChange={(e) => setEmailID(e.target.value)}
          />
        </div>

        <div className="submit-button-customer-form">
          <button onClick={placeOrder}>Place Order</button>
        </div>
      </div>
    </div>
  );
};

export default FormCart;

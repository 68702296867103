import React from "react";
import { useParams } from "react-router-dom";
import SearchPage from "./SearchPage";

const CategorySearch = ({ myproducts }) => {
  const { category } = useParams();
  return (
    <div>
      <SearchPage category={category} products={myproducts} />
    </div>
  );
};

export default CategorySearch;

import React from "react";
import { Link } from "react-router-dom";

const CartHeader = ({ cartCount }) => {
  return (
    <Link to="/cart">
      <div className="cart-icon-header">
        {cartCount ? <p>{cartCount.length}</p> : ""}
        <i className="fa-solid fa-shopping-bag"></i>
      </div>
    </Link>
  );
};

export default CartHeader;
